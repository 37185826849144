import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { sendRequest } from "config/apiFunctions";
import { InputAdornment } from "@mui/material";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const validationSchema = yup.object().shape({
  fullName: yup.string().required("To'liq ism kiritish majburiy"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]{9}$/, "Telefon raqam 9 ta raqamdan iborat bo'lishi kerak")
    .required("Telefon raqam kiritish majburiy"),
  password: yup.string().required("Parol kiritish majburiy"),
  status: yup.string().required("Status tanlash majburiy"),
});

export default function Data() {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const itemsPerPage = 10;
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });


  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);

  const { control: addControl, handleSubmit: handleAddSubmit, reset: resetAddForm } = useForm({
    defaultValues: {
      fullName: "",
      phoneNumber: "",
      password: "",
      role: "ADMIN",
      status: "INACTIVE"
    },
    resolver: yupResolver(validationSchema)
  });

  const { control: editControl, handleSubmit: handleEditSubmit, reset: resetEditForm } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const showAlert = (message, severity) => {
    setAlertInfo({ open: true, message, severity });
  };

  const openMenu = (event, item) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuItem(item);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
    setMenuItem(null);
  };

  const createRows = useCallback((data) => {
    return data.map((item) => ({
      client: <Author name={item.fullName ?? "-"} />,
      phone: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.phoneNumber ?? "-"}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      action: (
        <div>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={(event) => openMenu(event, item)}
          >
            more_vert
          </Icon>
        </div>
      ),
    }));
  }, [openMenu]);
  const fetchUsers = async (params) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({ method: "get", url: "/admin", token, params });
      if (res.success) {
        console.log(res);
        setData(res.data);
        setTotal(res.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertInfo({ ...alertInfo, open: false });
  };


  const handleOpenEditModal = useCallback((item) => {
    setSelectedItem(item);
    resetEditForm(item);
    setEditModalOpen(true);
    closeMenu();
  }, [closeMenu, resetEditForm]);

  const handleOpenDeleteModal = useCallback((item) => {
    setSelectedItem(item);
    setDeleteModalOpen(true);
    closeMenu();
  }, [closeMenu]);

  useEffect(() => {
    console.log("data", data);
    if (data.length) {
      const filteredData = data.filter(item => {
        const fullName = (item.fullName ?? "").toLowerCase();

        const filter = (filterValue ?? "").toLowerCase();
        return fullName.includes(filter);
      });

      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = filteredData.slice(startIndex, endIndex);

      const newRows = paginatedData.map((item) => ({
        client: <Author name={item.fullName ?? "-"} />,
        phone: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {item.phoneNumber ?? "-"}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={item.status == "ACTIVE" ? "Faol" : item.status == "UNVERIFIED" ? "Faol Emas" : item.status} color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <div>
            <Icon
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              fontSize="small"
              onClick={(event) => openMenu(event, item)}
            >
              more_vert
            </Icon>
          </div>
        ),
      }));
      setRows(newRows);
      setTotal(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [data, page, filterValue]);

  const renderMenu = useCallback(() => (
    <Menu
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menuAnchorEl)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => { handleOpenDeleteModal(menuItem); closeMenu(); }}>O'chirish</MenuItem>
      <MenuItem onClick={() => { handleOpenEditModal(menuItem); closeMenu(); }}>O'zgartirish</MenuItem>
    </Menu>
  ), [menuAnchorEl, menuItem, handleOpenDeleteModal, handleOpenEditModal]);

  const handleOpenAddModal = useCallback(() => {
    resetAddForm();
    setAddModalOpen(true);
  }, [resetAddForm]);

  const handleDelete = useCallback(async () => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    try {
      const res = await sendRequest({
        method: "delete",
        url: `/admin/${selectedItem.id}`,
        token
      });
      if (res.success) {
        // const updatedData = await fetchUsers(page, filterValue);
        const updatedData = data.filter(item => item.id !== selectedItem.id);
        setData(updatedData);
        setRows(createRows(updatedData));
        setDeleteModalOpen(false);
        showAlert("Operator muvaffaqiyatli o'chirildi", 'success');
      } else {
        console.error("Delete failed:", res.error);
        showAlert("Operatorni o'chirishda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Delete error:", error);
      showAlert("Operatorni o'chirishda xatolik yuz berdi", 'error');
    }
  }, [selectedItem, page, filterValue, fetchUsers, createRows, showAlert]);



  const onAddSubmit = async (formData) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    formData.phoneNumber = `+998${formData.phoneNumber}`;
    try {
      const res = await sendRequest({
        method: "post",
        url: "/admin",
        token,
        data: formData
      });
      console.log(res);
      if (res.success) {
        const updatedData = [res.data, ...data];
        setData(updatedData);
        setRows(createRows(updatedData));
        setAddModalOpen(false);
        resetAddForm();
        showAlert("Yangi Operator muvaffaqiyatli qo'shildi", 'success');
      } else {
        console.error("Add failed:", res.data.message[0]);
        showAlert("Yangi Operator qo'shishda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Add error:", error);
      showAlert("Yangi Operator qo'shishda xatolik yuz berdi", 'error');
    }
  };

  const onEditSubmit = async (formData) => {
    const user = localStorage.getItem("user");
    const token = user ? JSON.parse(user).token : null;
    if (formData.phoneNumber) {
      formData.phoneNumber = `+998${formData.phoneNumber}`;
    }
    try {
      const res = await sendRequest({
        method: "patch",
        url: `/admin/${selectedItem.id}`,
        token,
        data: formData
      });
      if (res.success) {
        const updatedData = data.map(item => item.id === selectedItem.id ? res.data : item);
        setData(updatedData);
        setRows(createRows(updatedData));
        setEditModalOpen(false);
        showAlert("Operator ma'lumotlari muvaffaqiyatli yangilandi", 'success');
      } else {
        console.error("Edit failed:", res.error);
        showAlert("Operator ma'lumotlarini yangilashda xatolik yuz berdi", 'error');
      }
    } catch (error) {
      console.error("Edit error:", error);
      showAlert("Operator ma'lumotlarini yangilashda xatolik yuz berdi", 'error');
    }
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const DeleteModal = useCallback(() => (
    <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          O'chirishni tasdiqlang
        </MDTypography>
        <MDTypography sx={{ mt: 2 }}>
          Haqiqatan ham bu operatorni o'chirib tashlamoqchimisiz?
        </MDTypography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setDeleteModalOpen(false)}>Bekor qilish</Button>
          <Button onClick={handleDelete} color="error">O'chirish</Button>
        </Box>
      </Box>
    </Modal>
  ), [deleteModalOpen, handleDelete]);

  const AddModal = useCallback(() => (
    <Modal open={addModalOpen} onClose={() => setAddModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          Yangi Operator qo'shish
        </MDTypography>
        <Box component="form" onSubmit={handleAddSubmit(onAddSubmit)} sx={{ mt: 2 }}>
          <Controller
            name="fullName"
            control={addControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="To'liq ismi"
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={addControl}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Telefon raqam"
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+998</InputAdornment>,
                }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '').slice(0, 9);
                  field.onChange(value);
                }}
              />
            )}
          />

          <Controller
            name="password"
            control={addControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Parol"
                type="password"
              />
            )}
          />
          <Controller
            name="status"
            control={addControl}
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select {...field} style={{ height: 40 }}>
                  <MenuItem value="ACTIVE">Faol</MenuItem>
                  <MenuItem value="INACTIVE">Faol emas</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setAddModalOpen(false)}>Bekor qilish</Button>
            <Button type="submit" color="primary">Qo'shish</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  ), [addModalOpen, handleAddSubmit, addControl, onAddSubmit]);

  const EditModal = useCallback(() => (
    <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
      <Box sx={modalStyle}>
        <MDTypography variant="h6" component="h2">
          Ma'lumotlarni tahrirlash
        </MDTypography>
        <Box component="form" onSubmit={handleEditSubmit(onEditSubmit)} sx={{ mt: 2 }}>
          <Controller
            name="fullName"
            control={editControl}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="To'liq ismi"
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={editControl}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label="Telefon raqam"
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+998</InputAdornment>,
                }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '').slice(0, 9);
                  field.onChange(value);
                }}
              />
            )}
          />

          <Controller
            name="status"
            control={editControl}
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Holati</InputLabel>
                <Select {...field} style={{ height: 40 }}>
                  <MenuItem value="ACTIVE">Faol</MenuItem>
                  <MenuItem value="INACTIVE">Faol emas</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setEditModalOpen(false)}>Bekor qilish</Button>
            <Button type="submit" color="primary">Saqlash</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  ), [editModalOpen, handleEditSubmit, editControl, onEditSubmit]);

  const Author = ({ name, phone }) => (
    <MDBox display="flex" flexDirection="column">
      <MDTypography variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption" color="secondary">
        {phone}
      </MDTypography>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );


  return {
    FilterInput: (
      <TextField
        fullWidth
        margin="normal"
        label="Ism  bo'yicha filtrlang"
        value={filterValue}
        onChange={handleFilterChange}
        sx={{ mb: 2 }}
      />
    ),
    AddButton: (
      <Button
        onClick={handleOpenAddModal}
        variant="contained"
        color="white"
        size="large"
        sx={{ borderRadius: "8px", float: "right", mt: 2 }}
      >
        Yangi operator qo'shish
      </Button>
    ),
    AlertComponent: (
      <Snackbar open={alertInfo.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertInfo.severity} sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>
    ),
    columns: [
      { Header: "Name", accessor: "client", width: "30%", align: "left" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
    Pagination: (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={total}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    ),
    DeleteModal,
    EditModal,
    AddModal,
    Menu: renderMenu(),
  };
}